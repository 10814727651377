import { type ReactElement, type FC, memo } from 'react';
import { useIntl } from './IntlContext';

type Props = Intl.DateTimeFormatOptions & {
    value: string | Date;
    children?: (result: string) => ReactElement;
};

const FormattedTime: FC<Props> = memo(({ value, children, ...dateTimeOptions }) => {
    const intl = useIntl();
    const result = intl.formatTime(value, dateTimeOptions);
    if (typeof children === 'function') {
        return children(result);
    }
    return <>{result}</>;
});

FormattedTime.displayName = 'FormattedTime';

export { FormattedTime };
