import IntlMessageFormat, {
    type FormatterCache as MessageFormatFormatterCache,
} from 'intl-messageformat';
import memoize, { type Cache } from 'fast-memoize';
import { type Locale } from 'dibs-intl/exports/locales';
import {
    type MessageDescriptor,
    type MessageValue,
    type IntlShape,
    type Formatters,
} from './types';

function createFastMemoizeCache<V>(store: Record<string, V>): Cache<string, V> {
    return {
        create() {
            return {
                has(key) {
                    return key in store;
                },
                get(key) {
                    return store[key];
                },
                set(key, value) {
                    store[key] = value;
                },
            };
        },
    };
}

// Cache creation of formatters, adapted from react-intl
function createDefaultFormatters(
    cache: MessageFormatFormatterCache & {
        message: Record<string, IntlMessageFormat>;
        list: Record<string, Intl.ListFormat>;
        relativeTime: Record<string, Intl.RelativeTimeFormat>;
    } = {
        number: {},
        dateTime: {},
        relativeTime: {},
        pluralRules: {},
        list: {},
        message: {},
    }
): Formatters {
    const formatters = {
        getNumberFormat: memoize((...args) => new Intl.NumberFormat(...args), {
            cache: createFastMemoizeCache(cache.number),
            strategy: memoize.strategies.variadic,
        }),
        getDateTimeFormat: memoize((...args) => new Intl.DateTimeFormat(...args), {
            cache: createFastMemoizeCache(cache.dateTime),
            strategy: memoize.strategies.variadic,
        }),
        getRelativeTimeFormat: memoize((...args) => new Intl.RelativeTimeFormat(...args), {
            cache: createFastMemoizeCache(cache.relativeTime),
            strategy: memoize.strategies.variadic,
        }),
        getListFormat: memoize((...args) => new Intl.ListFormat(...args), {
            cache: createFastMemoizeCache(cache.list),
            strategy: memoize.strategies.variadic,
        }),
        getPluralRules: memoize((...args) => new Intl.PluralRules(...args), {
            cache: createFastMemoizeCache(cache.pluralRules),
            strategy: memoize.strategies.variadic,
        }),
    };

    const getMessageFormat = memoize(
        (message, locales, overrideFormats, opts) =>
            new IntlMessageFormat(message, locales, overrideFormats, {
                formatters,
                ...(opts || {}),
            }),
        {
            cache: createFastMemoizeCache(cache.message),
            strategy: memoize.strategies.variadic,
        }
    );

    // using `as` to make sure formatters object is the same object passed to
    // IntlMessageFormat, so we can spyOn it in tests
    (formatters as Formatters).getMessageFormat = getMessageFormat;

    return formatters as Formatters;
}

type CreateIntlOpts = {
    locale: Locale;
    defaultLocale?: string;
    messages?: Record<string, string>;
    disableTranslations?: boolean;
};

class IntlContainer implements IntlShape {
    locale: Locale;
    #disableTranslations: boolean;
    #isDefaultLocale: boolean;
    formatters: Formatters;
    messages: Record<string, string>;
    #warnedMessageIds = new Set<string>();
    constructor({
        locale,
        defaultLocale = 'en-US',
        messages,
        disableTranslations = false,
    }: CreateIntlOpts) {
        this.locale = locale;
        this.#isDefaultLocale = locale === defaultLocale;
        this.messages = messages || {};
        this.formatters = createDefaultFormatters();
        this.#disableTranslations = disableTranslations;
    }
    #warnMissingMessage = (id: string): void => {
        if (!this.#warnedMessageIds.has(id)) {
            // eslint-disable-next-line no-console
            console.warn(
                `[dibs-react-intl]: message missing for id "${id}", using default message.`
            );
            this.#warnedMessageIds.add(id);
        }
    };
    formatMessage = (
        messageDescriptor: MessageDescriptor,
        values?: Record<string, MessageValue>
    ): string => {
        // if default locale, do not use stored message object.
        // use default message from passed message descriptor
        let message;
        if (this.#disableTranslations || this.#isDefaultLocale) {
            message = messageDescriptor.defaultMessage;
        } else if (this.messages[messageDescriptor.id]) {
            message = this.messages[messageDescriptor.id];
        } else {
            this.#warnMissingMessage(messageDescriptor.id);
            message = messageDescriptor.defaultMessage;
        }
        const formatter = this.formatters.getMessageFormat(message, this.locale);
        try {
            return formatter.format(values) as string;
        } catch (err) {
            if (!this.#isDefaultLocale) {
                // eslint-disable-next-line no-console
                console.error(
                    `Failed to format message ${messageDescriptor.id}. Will try the defaultMessage.`
                );
                const defaultFormatter = this.formatters.getMessageFormat(
                    messageDescriptor.defaultMessage,
                    'en-US'
                );
                return defaultFormatter.format(values) as string;
            }
            throw err;
        }
        // types for IntlMessageFormat#format say its possible to return an array
        // but not possible with our inputs
    };
    formatNumber = (value: number, opts?: Intl.NumberFormatOptions): string => {
        const formatter = this.formatters.getNumberFormat(this.locale, opts);
        return formatter.format(value);
    };
    formatDate = (value: string | number | Date, opts?: Intl.DateTimeFormatOptions): string => {
        if (typeof value === 'string') {
            value = new Date(value);
        }
        const formatter = this.formatters.getDateTimeFormat(this.locale, opts);
        return formatter.format(value);
    };
    formatRelativeTime = (
        value: number,
        unit: Intl.RelativeTimeFormatUnit,
        opts?: Intl.RelativeTimeFormatOptions
    ): string => {
        const formatter = this.formatters.getRelativeTimeFormat(this.locale, opts);
        return formatter.format(value, unit);
    };
    formatList = (value: Iterable<string>, opts?: Intl.ListFormatOptions): string => {
        const formatter = this.formatters.getListFormat(this.locale, opts);
        return formatter.format(value);
    };
    formatTime = (value: string | number | Date, opts?: Intl.DateTimeFormatOptions): string => {
        if (typeof value === 'string') {
            value = new Date(value);
        }

        if (!opts || (!opts.hour && !opts.minute && !opts.second)) {
            opts = { ...opts, hour: 'numeric', minute: 'numeric' };
        }

        const formatter = this.formatters.getDateTimeFormat(this.locale, opts);
        return formatter.format(value);
    };
}

export function createIntl(opts: CreateIntlOpts): IntlShape {
    return new IntlContainer(opts);
}
