import * as React from 'react';
import { type Context, type FC, type ReactNode } from 'react';
import { type Locale } from 'dibs-intl/exports/locales';
import { createIntl } from './createIntl';
import { type IntlShape } from './types';
import { type AsyncLocalStorage } from 'node:async_hooks';

let asyncLocalStorage: AsyncLocalStorage<IntlShape>;
export function setAsyncLocalStorage(val: AsyncLocalStorage<IntlShape>): void {
    asyncLocalStorage = val;
}

const { createContext, useContext: reactClientUseContext } = React;

let IntlContext: Context<IntlShape | null>;

let useContext: typeof reactClientUseContext;
if (typeof createContext !== 'undefined') {
    IntlContext = createContext<IntlShape | null>(null);
    IntlContext.displayName = 'DibsIntlContext';
    useContext = reactClientUseContext;
} else {
    //@ts-expect-error
    useContext = function () {
        return asyncLocalStorage.getStore();
    };
}

export { IntlContext };

type Props = {
    messages?: Record<string, string>;
    locale: Locale;
    defaultLocale?: string;
    disableTranslations?: boolean;
    children: ReactNode;
};

export function useIntl(): IntlShape {
    const intl = useContext(IntlContext);

    if (!intl) {
        throw new Error(
            '[dibs-react-intl] Could not find required `intl` object. <IntlProvider> needs to exist in the component ancestry.'
        );
    }

    return intl;
}

export const IntlProvider: FC<Props> = ({ messages, locale, children, disableTranslations }) => {
    const intl = createIntl({ locale, messages, defaultLocale: 'en-US', disableTranslations });
    return <IntlContext.Provider value={intl}>{children}</IntlContext.Provider>;
};
